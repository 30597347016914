@import '@pushpress/shared-components/build/styles/config.scss';

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: $wolf-200-default;
  overflow: hidden;
}

.bar {
  width: 100%;
  height: 100%;
  background-color: $pacific-primary-400-default;
  transform-origin: left center;
}
