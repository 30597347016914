@import "@pushpress/shared-components/build/styles/config.scss";

.selectProfileButton {
  all: unset; /* Resets all inherited and default styles */
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: calc(100% + 50px);
  margin-left: -25px;
  margin-right: -25px;
  padding: 0 25px;
  height: 70px;

  &:hover {
    background-color: $wolf-100;
  }

  .clientLogoAndName {
    display: flex;
  }

  .companyName {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .status {
    background-color: $wolf-200;
    border-radius: 4px;
    padding: $grid-unit-05 $grid-unit;

    span {
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 14px;
      text-transform: uppercase;
    }
  }
}
