@import "@pushpress/shared-components/build/styles/config.scss";

.container {
  display: flex;
  background-color: var(--pacific-primary-600);
  min-height: 100vh;
  padding-bottom: 20px;

  @media (max-width: $breakpoint-mobile) {
    background-color: $white;
  }

  .content {
    position: relative;
    overflow: hidden;
    background-color: $white;
    border-radius: 8px;
    margin: auto;
    margin-top: 20px;
    padding: 30px 25px;
    width: 400px;

    @media (max-width: $breakpoint-mobile) {
      margin-top: 0;
      padding-top: 40px;
    }

    .logo {
      height: 50px;
    }
  }
}
